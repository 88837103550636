<template lang="pug">
  div
    .about
      .enforce-max-width
        b-row
          b-col#info(cols="12", md="10", offset-md="1", lg="8", offset-lg="2")
            h2 Artist Bio
            p
              |I have been an artist for most of my life and have vague recollections of studying art in the studio of impressionistic landscape artist, Martin Pribil, when I was nine years old. In the 80’s I took the fine arts path in college and as a young adult I primarily did freelance work that included privately commissioned pieces, layout and design work for ad agencies, and greeting card designs for a company in New York.
              br
              br
              |Throughout the decades that followed, my artistic endeavors were delayed as I happily devoted myself to raising two beautiful children. During those years I followed life through the eyes of my daughter and son as young children, and continuing with them as they navigated through the complex process of becoming young adults. Those experiences and others shaped my own creativity as an artist, and while unable to fulfill my artistic interests at that time, I began to develop concepts and formulate ideas for what I hoped to accomplish in my future.
              br
              br
              |Currently, with children grown and obligations fulfilled, I have become enriched by my own process of discovery. Enlightened by experiences of the past and revitalized by the present I continue to evolve and grow through my artwork. Ultimately I am committed to creating and sharing works of art that are both meaningful to me, and inspiring to others.
            hr
            h2 Artist Statement
            p
              |My artwork is largely motivated by nature, in everything that lives and grows and partakes in life’s fragile cycles. Fascinated by structure and how things are connected I enjoy the challenge of creating visual harmony, balance, and unity between the fundamental elements of color and light, texture and contrast, space and form.
              br
              br
              |As each piece of artwork starts with a concept I use my own photographs to develop a particular composition and utilizing the above components. I further modify those images by enhancing and exaggerating specific characteristics to create a picture that is unique and inspiring, an experience that begins with something familiar, and transforms it into something that is extraordinary.
              br
              br
              |Nature and the environment have always provided joy and inspiration for a lot of the artwork that I create. In the spirit of giving, and to help support these important causes,&nbsp;
              strong 10% of sales from “Sydney and Betty”&nbsp;
              |and&nbsp;
              strong “Gnatcatchers”&nbsp;
              |will be donated to the&nbsp;
              strong Forest Park Living Lab&nbsp;
              |project, and&nbsp;
              strong 10% of sales from “Winter Squirrel”&nbsp;
              |will be donated to&nbsp;
              strong Wildlife Rescue
              |.
              br
              br
              em Medium: primarily watercolor
</template>

<script>
export default {
  mounted() {
    document.getElementById("main").classList.remove("dark-bg");
    document.getElementById("main").classList.remove("dark-bg-mobile");
  }
};
</script>

<style scoped lang="less">
.about {
  transition: all 0.5s ease;
  font-family: var(--sans-serif);
  padding-top: var(--nav-height) !important;
  padding-bottom: 120px !important;
  #info {
    text-align: left;
    background: rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 0.96);
    border-radius: 6px;
    padding: 20px 40px;
  }
  p.small-caps {
    transition: all 0.5s ease;
    color: var(--secondary-color);
    font-weight: 600;
  }
  a {
    color: var(--secondary-color);
  }
}
@media (max-width: 767px) {
}
</style>
