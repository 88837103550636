<template lang="pug">
  div
    .contact
      .enforce-max-width
        b-row
          b-col#contact(cols="12", sm="6", xl="5", offset-xl="1")
            .inner
              h2 Contact
              hr
              p
                | If you have any questions about my artwork or are interested in purchasing a piece, please feel free to send me a message at&nbsp;
                a(href="mailto:lynnkaminsky317@gmail.com", target="_blank") lynnkaminsky317@gmail.com
                |.
                br
                br
                |I'm always happy to discuss my work with interested parties and help you find the perfect piece for your collection.
          b-col#payments(cols="12", sm="6", xl="5")
            .inner
              h2 Payments
              hr
              p If you have already arranged a purchase and need to make a payment, you can use the PayPal link below to complete your transaction. Please make sure to include the name of the artwork you're purchasing in the payment notes.
              b-button(href="https://paypal.me/LynnKaminsky", target="_blank")
</template>

<script>
export default {
  mounted() {
    document.getElementById("main").classList.remove("dark-bg");
    document.getElementById("main").classList.remove("dark-bg-mobile");
  }
};
</script>

<style scoped lang="less">
p {
  text-align: left;
  a {
    color: var(--btn-color) !important;
    cursor: pointer !important;
    transition: all 0.25s ease;
    text-decoration: underline !important;
    text-decoration-color: rgba(207, 114, 147, 0) !important;
    &:hover,
    &:active {
      color: var(--btn-hover) !important;
      text-decoration: underline !important;
      text-decoration-color: var(--btn-hover) !important;
    }
  }
}
.b-icon {
  color: var(--btn-color);
  font-size: 2rem;
}
.btn {
  background-image: url("../assets/images/paypal.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: var(--btn-color);
  display: block;
  width: 100%;
  max-width: 220px;
  height: 50px;
  border: 10px solid var(--btn-color);
  margin: 20px auto 10px;
  transition: all 0.25s ease;

  &:hover,
  &:active {
    background-color: var(--btn-hover);
    border: 10px solid var(--btn-hover);
  }
}
.contact {
  transition: all 0.5s ease;
  font-family: var(--sans-serif);
  padding-top: var(--nav-height) !important;
  padding-bottom: 120px !important;
  #payments,
  #contact {
    text-align: center;
    padding: 10px;
    > .inner {
      background: rgba(var(--darkBG-R), var(--darkBG-G), var(--darkBG-B), 0.96);
      padding: 20px 40px;
      border-radius: 6px;
    }
  }
  p.small-caps {
    transition: all 0.5s ease;
    color: var(--secondary-color);
    font-weight: 600;
  }
  a {
    color: var(--secondary-color);
  }
}
@media (max-width: 767px) {
  #app #router-content > div > div.contact {
    padding-bottom: 80px !important;
    #payments,
    #contact {
      padding: 10px 0;
    }
  }
}
</style>
